import React, { memo, useState } from "react"
import style from "styles/playtoearn.module.css"
import find from 'public/image/find.webp'
import win from 'public/image/win.webp'
import rent from 'public/image/rent.webp'
import nft from 'public/image/nft.webp'
import food from 'public/image/foodEat.webp'
import drink from 'public/image/drink.webp'
import person from 'public/image/person.webp'
import personMobile from 'public/image/personMobile.webp'
import Image from 'next/image'
import useLocale from '../../../../hooks/useLocale'

const PlayToEarn = () => {
	const {playToEarnSection} = useLocale()
	const [item, setItem] = useState(0);
	return (
		<div className={style.play}>
			<div className={style.container}>
				<div className={style.intro}>
					<div className={style.introContainer}>
						<h2 className={style.title} data-aos-once="true" data-aos="slide-up">{playToEarnSection.title}</h2>
						<h3 className={style.subtitle} data-aos-once="true" data-aos="slide-up">{playToEarnSection.subtitle}</h3>
					</div>
					<div
						className={style.playEarn}
						data-aos-once="true" data-aos="fade-up"
						data-aos-anchor-placement="bottom"
						data-aos-delay={300}>
						<div className={style.earn}>
							<h4 className={style.playTitleBig}>{playToEarnSection.earn}</h4>
							<div className={style.block}>
								<Image
									src={find}
									alt=""
									width={140}
									height={140}
									// className='w-24 h-24'
								/>
								<div>
									<h5 className={style.blockTitle}>{playToEarnSection.earnFirstTitle}</h5>
									<p className={style.blockText}>{playToEarnSection.earnFirstText}</p>
								</div>
							</div>
							<div className={style.block}>
								<Image
									src={win}
									alt=""
									width={140}
									height={140}
								/>
								<div>
									<h5 className={style.blockTitle}>{playToEarnSection.earnSecondTitle}</h5>
									<p className={style.blockText}>{playToEarnSection.earnSecondText}</p>
								</div>
							</div>
							<div className={style.block}>
								<Image
									src={rent}
									alt=""
									width={140}
									height={140}
								/>
								<div>
									<h5 className={style.blockTitle}>{playToEarnSection.earnThirdTitle}</h5>
									<p className={style.blockText}>{playToEarnSection.earnThirdText}</p>
								</div>
							</div>
						</div>
						<div className={style.person}>
							<div className={style.personImg}>
								<div className={style.image}>
									<Image
										src={person}
										alt=""
										layout="responsive"
										objectFit="cover"
									/>
								</div>
							</div>
						</div>
						<div className={style.spend}>
							<h4 className={style.playTitleBig}>{playToEarnSection.spend}</h4>
							<div className={style.block}>
								<Image
									src={nft}
									alt=""
									width={140}
									height={140}
								/>
								<div>
									<h5 className={style.blockTitle}>{playToEarnSection.spendFirstTitle}</h5>
									<p className={style.blockText}>{playToEarnSection.spendFirstText}</p>
								</div>
							</div>
							<div className={style.block}>
								<Image
									src={food}
									alt=""
									width={140}
									height={140}
								/>
								<div>
									<h5 className={style.blockTitle}>{playToEarnSection.spendSecondTitle}</h5>
									<p className={style.blockText}>{playToEarnSection.spendSecondText}</p>
								</div>
							</div>
							<div className={style.block}>
								<Image
									src={drink}
									alt=""
									width={140}
									height={140}
								/>
								<div>
									<h5 className={style.blockTitle}>{playToEarnSection.spendThirdTitle}</h5>
									<p className={style.blockText}>{playToEarnSection.spendThirdText}</p>
								</div>
							</div>
						</div>
					</div>
					<div
						className={style.playEarnMobile}
						data-aos-once="true" data-aos="fade-up">
						<Image
							src={personMobile}
							alt=""
						/>
						<div className={style.choose}>
							<div onClick={() => setItem(0)} className={style.select}>
								<h4 className={`${style.playTitleBig} ${item === 0 && style.chosen}`}>{playToEarnSection.earn}</h4>
							</div>
							<div onClick={() => setItem(1)} className={style.select}>
								<h4 className={`${style.playTitleBig} ${item === 1 && style.chosen}`}>{playToEarnSection.spend}</h4>
							</div>
						</div>
						{item === 0 && (
							<>
								<div className={style.block}>
									<Image
										src={find}
										alt=""
										width={110}
										height={110}
									/>
									<div>
										<h5 className={style.blockTitle}>{playToEarnSection.earnFirstTitle}</h5>
										<p className={style.blockText}>{playToEarnSection.earnFirstText}</p>
									</div>
								</div>
								<div className={style.block}>
									<Image
										src={win}
										alt=""
										width={110}
										height={110}
									/>
									<div>
										<h5 className={style.blockTitle}>{playToEarnSection.earnSecondTitle}</h5>
										<p className={style.blockText}>{playToEarnSection.earnSecondText}</p>
									</div>
								</div>
								<div className={style.block}>
									<Image
										src={rent}
										alt=""
										width={110}
										height={110}
									/>
									<div>
										<h5 className={style.blockTitle}>{playToEarnSection.earnThirdTitle}</h5>
										<p className={style.blockText}>{playToEarnSection.earnThirdText}</p>
									</div>
								</div>
							</>
						)}
						{item === 1 && (
							<>
								<div className={style.block}>
									<Image
										src={nft}
										alt=""
										width={110}
										height={110}
									/>
									<div>
										<h5 className={style.blockTitle}>{playToEarnSection.spendFirstTitle}</h5>
										<p className={style.blockText}>{playToEarnSection.spendFirstText}</p>
									</div>
								</div>
								<div className={style.block}>
									<Image
										src={food}
										alt=""
										width={110}
										height={110}
									/>
									<div>
										<h5 className={style.blockTitle}>{playToEarnSection.spendSecondTitle}</h5>
										<p className={style.blockText}>{playToEarnSection.spendSecondText}</p>
									</div>
								</div>
								<div className={style.block}>
									<Image
										src={drink}
										alt=""
										width={110}
										height={110}
									/>
									<div>
										<h5 className={style.blockTitle}>{playToEarnSection.spendThirdTitle}</h5>
										<p className={style.blockText}>{playToEarnSection.spendThirdText}</p>
									</div>
								</div>
							</>
						)}
					</div>
				</div>
			</div>
		</div>
	)
}

export default memo(PlayToEarn)
